import React, { useState } from "react";
// import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import { useStore } from "../../store/StoreContext";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/routePaths";
import { colors } from "../../constants/colors";
import Snackbar from "../../components/Snackbar/Snackbar";
import img from "../../assets/svg/bg-image.svg";
import IFWIcon from "../../assets/svg/IFWIcon";
import FWLogoWhite from "../../assets/images/fw-logo-white.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  // FormControlLabel,
  // Checkbox,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import "./styles.scss";

const SignIn = observer(() => {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    showPassword: false,
    // rememberMe: false,
  });
  const [error, setError] = useState<string>("");
  const [unauthorizedUser, setUnauthorizedUser] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { authStore } = useStore();
  let navigate = useNavigate();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (prop: keyof typeof formValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };
  // TOOD: Comment all related to Remember me
  // const handleRememberMeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormValues({ ...formValues, rememberMe: event.target.checked });
  // };

  // useEffect(() => {
  //   const savedEmail = Cookies.get("email");
  //   const savedPassword = Cookies.get("password");
  //   if (savedEmail && savedPassword) {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       email: savedEmail,
  //       password: savedPassword,
  //       rememberMe: true
  //     }));
  //   }
  // }, []);

  const signIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      await authStore.login(formValues.email, formValues.password);
      setError("");
      // TOOD: Comment all related to Remember me
      // if (formValues.rememberMe) {
      //   Cookies.set("email", formValues.email, { expires: 1095 });
      //   Cookies.set("password", formValues.password, { expires: 1095 });
      // } else {
      //   Cookies.remove("email");
      //   Cookies.remove("password");
      // }

      if (authStore.isNeedChangePassword) {
        forgotPassword();
      }

      return navigate(ROUTE_PATHS.HOME);
    } catch (error: any) {
      const key = error?.name;
      switch (key) {
        case "incorrect_email_or_password":
        case "Incorrect email.":
          setError("Incorrect email and/or password. Please try again.");
          break;
        default:
          setUnauthorizedUser(true);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = () => {
    return navigate(ROUTE_PATHS.FORGOT_PASS);
  };

  const renderSignIn = () => {
    return (
      <>
        <FormControl error={!!error} variant="outlined" fullWidth margin="normal">
          <InputLabel required htmlFor="sign-in-input-email">
            Email
          </InputLabel>
          <OutlinedInput
            required
            id="sign-in-input-email"
            label="Email"
            placeholder="Email"
            value={formValues.email}
            onChange={handleChange("email")}
            endAdornment={
              <InputAdornment position="end">
                {error ? (
                  <ErrorOutlineIcon color="error" fontSize="large" id="sign-in-icon-error-email" />
                ) : (
                  formValues.email && (
                    <CheckCircleOutlineIcon fontSize="large" color="primary" id="sign-in-icon-validator-email" />
                  )
                )}
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl error={!!error} variant="outlined" fullWidth margin="normal">
          <InputLabel required htmlFor="sign-in-input-pass">
            Password
          </InputLabel>
          <OutlinedInput
            required
            placeholder="Password"
            id="sign-in-input-pass"
            type={formValues.showPassword ? "text" : "password"}
            value={formValues.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {formValues.showPassword ? (
                    <VisibilityOff fontSize="large" id="sign-in-icon-eye-close" />
                  ) : (
                    <Visibility fontSize="large" id="sign-in-icon-eye-open" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {error && (
            <Typography
              id="sign-in-text-general-error"
              variant="body1"
              color="error"
              fontSize="1.3rem"
              margin=".6rem 0"
            >
              {error}
            </Typography>
          )}
        </FormControl>
        {/* TOOD: Comment all related to Remember me */}
        {/* <FormControlLabel
          control={
            <Checkbox
            checked={formValues.rememberMe} 
            onChange={handleRememberMeCheckbox} 
            
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: "22px", 
                borderRadius: "6px", 
                backgroundColor: colors.white,
                color: colors.grey,
              },
              "&.Mui-checked .MuiSvgIcon-root": {
                backgroundColor: colors.aqua.four,
                color: colors.white, 
                border: `2px solid ${colors.white}`, 
              },
              "&:hover": {
                backgroundColor: "transparent", 
              },
              "& .MuiTouchRipple-root": {
                display: "none", 
              },
            }}
          />
          } 
          label={<Typography fontSize="1.4rem" sx={{ color: colors.black }}>Remember Me</Typography>}  
          sx={{ 
            marginBottom: "1rem" ,
          }}
        /> */}
        <Button
          id="sign-in-btn-submit"
          type="submit"
          variant="contained"
          disabled={loading}
          color="primary"
          size="large"
          sx={{ borderRadius: ".8rem" }}
        >
          {loading ? (
            <CircularProgress
              size={28}
              sx={{
                color: colors.aqua.four,
              }}
            />
          ) : (
            <Typography sx={{ textTransform: "capitalize", fontSize: "1.5rem" }}>Sign in</Typography>
          )}
        </Button>
        {/* TODO: We'll add the Forgot Password when the backend fix the endpoint
        <Link
          id="sign-in-link-forgot-pass"
          textAlign="center"
          onClick={forgotPassword}
          sx={{ cursor: "pointer", display: "block", marginTop: "1rem" }}
        >
          Forgot password?
        </Link>{" "}*/}
        <Typography sx={{ fontSize: "1.4rem", marginTop: "1rem", fontStyle: "italic" }}>
          Email{" "}
          <Link
            id="sign-in-link-forgot-pass"
            href="mailto:appsupport@fasterwaytofatloss.com"
            sx={{ cursor: "pointer" }}
            underline="none"
          >
            appsupport@fasterwaytofatloss.com
          </Link>{" "}
          for password assistance
        </Typography>
      </>
    );
  };

  const goToSignIn = () => {
    setUnauthorizedUser(false);
  };

  const renderUnauthorizedUser = () => {
    return (
      <Typography variant="body1" fontSize="1.6rem" margin="2.5rem 0 2rem 0">
        We are having trouble loading your data. Please email
        <Link
          id="sign-unauthorizeduser-mail"
          href="mailto:coachsupport@fasterwaytofatloss.com"
          underline="hover"
          sx={{ cursor: "pointer", display: "block" }}
        >
          coachsupport@fasterwaytofatloss.com
        </Link>
        to report this issue.
        <Link
          id="sign-unauthorizeduser-return"
          underline="hover"
          onClick={goToSignIn}
          sx={{ cursor: "pointer", display: "block", marginTop: "6rem", textAlign: "center" }}
        >
          Return to Sign In
        </Link>
      </Typography>
    );
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={signIn}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        sx={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <img alt="FasterWay" src={FWLogoWhite} style={{ marginBottom: "4rem", width: "20rem" }} />
        <Box
          sx={{
            backgroundColor: colors.white,
            height: unauthorizedUser ? "" : "44.2rem",
            //TOOD: Comment all related to Remember me
            // minHeight: unauthorizedUser ? "" : "44.2rem",
            width: "36.8rem",
            borderRadius: "1.2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-evenly",
            paddingX: "3rem",
            paddingY: "4rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              paddingBottom: "1.6rem",
            }}
          >
            <IFWIcon width="4.8rem" height="4.8rem" />
            <Typography variant="h3" sx={{ marginLeft: "1rem" }}>
              CoachConnect
            </Typography>
          </Box>
          {unauthorizedUser ? renderUnauthorizedUser() : renderSignIn()}
        </Box>
        <Snackbar
          open={showSnackBar}
          onClose={() => setShowSnackBar(false)}
          message={"Something went wrong. Try again later."}
          severity="error"
        />
      </Box>
    </>
  );
});

export default SignIn;
